<template>
  <CsModal ref="csDialogRef" size="full" @close="dialogClose">
    <transition name="fragrance-complete">
      <div
        v-if="completeOverlayVisible"
        class="absolute z-[60] bg-white top-0 left-0 right-0 bottom-0 flex items-center justify-center rounded-xl transition-opacity"
      >
        <div tabindex="0" @focus="focusCompleteButton" />
        <div ref="completeOverlay" class="flex flex-col">
          <div class="mb-10 h1">Great choices!</div>
          <div
            v-for="choice in createRichData(preSelected)"
            :key="choice.product?.objectID"
            class="flex items-center gap-4 mb-4"
          >
            <img
              class="w-11 h-11 rounded-lg"
              :src="choice.product?.image"
              :alt="choice.product?.imageAlt"
            />
            <div class="flex-1 text-md">{{ choice.product?.name }}</div>
            <div
              class="content-center bg-gray-tint w-11 h-11 text-center rounded-full user-select-none"
            >
              <span>
                {{ choice.amount }}
              </span>
            </div>
          </div>
          <div class="flex gap-4 justify-center mt-4">
            <button
              ref="completeResetButton"
              class="bg-gray-tint text-onyx-500 px-4 py-2 rounded-full border-none font-bold text-md"
              @click="cancelSuccessOverlay"
            >
              Edit
            </button>

            <button
              ref="completeConfirmButton"
              class="bg-onyx-500 text-white px-4 py-2 rounded-full border-none font-bold text-md"
              @click="submit"
            >
              Done
            </button>
          </div>
        </div>
        <div tabindex="0" @focus="focusResetButton" />
      </div>
    </transition>
    <div
      class="p-0 grid grid-rows-[auto,auto,1fr] h-full"
      :class="completeOverlayVisible && 'pointer-events-none'"
    >
      <!-- header -->

      <div
        class="grid grid-cols-[1fr,auto] mini:pt-11 py-3 px-5 mini:px-11 w-full box-border"
      >
        <div class="flex flex-col justify-center">
          <div class="h4">
            {{ selectionConfig.title }}
            <Spinner :spin="loading" size="small" class="ml-2" />
          </div>
          <div v-if="selectionConfig.quantity > 1">
            <template v-if="selectionConfig.quantity !== preselectQuantity">
              Choose
              {{ selectionConfig.quantity - preselectQuantity }}
              {{ selectionConfig.quantity - preselectQuantity > 0 && "more" }}
            </template>
            <template v-else> Great choice! </template>
          </div>
        </div>

        <div class="flex items-center gap-2">
          <button
            v-if="preselectQuantity == selectionConfig.quantity"
            class="h-11 bg-gray-tint px-4 font-semibold rounded-full border-none"
            @click="preSelected = {}"
          >
            <SvgIcon icon="rotate-ccw" class="w-5 h-5" />
          </button>
          <button
            v-if="preselectQuantity == selectionConfig.quantity"
            class="h-11 bg-onyx-500 text-white px-4 font-semibold rounded-full border-none text-md"
            @click="submit"
          >
            Done
          </button>
          <form v-else method="dialog">
            <button
              type="submit"
              class="w-11 h-11 bg-gray-tint rounded-full border-none flex items-center justify-center focus:s dow-focus focus:outline-none"
              @click="cancelChooseProcess"
            >
              <SvgIcon icon="x" class="h-5 w-5" />
            </button>
          </form>
        </div>
      </div>

      <!-- Categories -->
      <div class="relative">
        <!-- Search-wrap -->
        <div
          class="transition-all py-2 duration-500 flex origin-right absolute inset-x-5 items-center gap-2 mini:inset-x-11"
          :class="
            searchOpen
              ? 'scale-x-100 opacity-100'
              : 'opacity-0 scale-x-0 pointer-events-none'
          "
        >
          <!-- SearchBar -->
          <div class="w-full">
            <div class="relative w-full flex items-center">
              <SvgIcon icon="search" class="w-5 h-5 absolute left-4" />
              <input
                ref="searchInput"
                v-model="searchQuery"
                :tabindex="searchOpen ? 0 : -1"
                class="block w-full h-11 bg-gray-tint rounded-full border-none pr-4 pl-11 text-smpr-11 box-border shadow focus:shadow-focus focus:outline-none"
                placeholder="Search for fragrances"
              />
            </div>
          </div>
        </div>

        <!-- Categories -->
        <div
          class="grid grid-cols-[1fr,theme(spacing.7)] max-w-full w-full box-border"
        >
          <div
            v-if="categories"
            class="pl-5 pr-12 py-2 mini:px-11 flex mini:flex-wrap overflow-x-auto overflow-y-visible gap-2"
          >
            <button
              v-for="category in Object.keys(categories)"
              :key="category"
              :class="
                userFacets.includes(category)
                  ? 'bg-onyx-500 text-white'
                  : 'bg-gray-tint text-onyx-400'
              "
              class="select-none h-11 px-3 rounded-full inline-flex border-0 font-semibold whitespace-nowrap transition-colors duration-100 items-center gap-1 focus:outline-none focus:shadow-focus focus:shadow-focus active:focus:shadow-transparent"
              :disabled="searchOpen"
              @click="applyCategory(category)"
            >
              {{ category }}
              <SvgIcon
                v-if="userFacets.includes(category)"
                icon="x"
                class="w-5 h-5"
              />
            </button>
          </div>
        </div>

        <!-- Search toggle -->
        <div
          class="absolute right-0 top-0 bottom-0 pr-5 py-2 mini:pr-11 box-border"
        >
          <div class="relative">
            <button
              class="w-11 h-11 bg-gray-tint rounded-full border-none z-10 shadow transition-shadow absolute right-0 top-0 focus:shadow-focus focus:outline-none"
              :class="
                searchOpen
                  ? 'shadow-transparent'
                  : 'shadow-[0px_0px_0px_10px_white]'
              "
              @click="toggleSearch"
            >
              <SvgIcon v-if="!searchOpen" icon="search" class="w-5 h-5" />
              <SvgIcon v-else icon="x" class="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>

      <!-- Product grid -->
      <div class="h-full overflow-y-auto">
        <div
          class="columns-xs gap-10 mini:px-11 px-5 pt-8 pb-6 [column-rule-style:solid] [column-rule-color:theme(colors.gray.200)] [column-rule-width:2px]"
        >
          <!-- Algolia No Results -->
          <div v-if="!loading && products?.length === 0" class="text-lg">
            No products found
          </div>
          <div
            v-for="product in products"
            :key="product.objectID"
            class="relative flex justify-between items-center gap-4 mb-4"
            :class="
              !product.variants.find(
                (variant) => variant.type_code === selectionConfig.type_code,
              )?.in_stock_everywhere && 'opacity-60'
            "
          >
            <img
              :src="product.image"
              :alt="product.imageAlt"
              class="w-11 h-11 select-none"
              :class="product.blending_elements ? 'rounded-full' : 'rounded-lg'"
            />

            <span
              class="min-w-0 block text-left text-md flex-1 select-none text-ellipsis whitespace-nowrap overflow-hidden"
              >{{ product.name }}
            </span>

            <!-- Out of Stock -->
            <div
              v-if="
                !product.variants.find(
                  (variant) => variant.type_code === selectionConfig.type_code,
                )?.in_stock_everywhere
              "
              class="py-1 px-2 rounded-lg font-bold select-none text-sm"
            >
              Out Of Stock
            </div>
            <!-- Not out of stock -->
            <SmallQuantSelect
              v-else
              :value="preSelected[product.product_id] || 0"
              :disable-increase="preselectQuantity >= selectionConfig.quantity"
              @onIncrease="preSelect(product.product_id)"
              @onDecrease="minusPreselect(product.product_id)"
            />
          </div>
        </div>
      </div>
    </div>
  </CsModal>
</template>

<script setup lang="ts">
  import algoliasearch, { type SearchIndex } from "algoliasearch"
  import CsDialog from "./CsDialog.vue"
  import { type AlgoliaFragrance } from "~/types/algoliaSchema"
  import debounce from "~/lib/debounce"

  const { handleError } = useAlgoliaErrorHandling()
  const { $config } = useNuxtApp()

  const uiStore = useUiStore()

  // Algolia
  const algoliaSearch = algoliasearch(
    $config.public.ALGOLIA_APPLICATION_ID,
    $config.public.ALGOLIA_API_KEY,
  )
  const index: SearchIndex = algoliaSearch.initIndex("Cs_Fragrance")

  // Context Types
  export interface FragranceSelectionConfig {
    type_code: AlgoliaFragrance["variants"][0]["type_code"]
    predefinedFacets?: string[]
    title: string
    quantity: number
    selectedProducts?: VariantSelection[]
  }

  // Config Setup

  const SELECTION_CONFIG_DEFAULT: FragranceSelectionConfig = {
    type_code: "1-oz-bottle",
    title: "Choose a fragrance",
    quantity: 1,
  }

  const selectionConfig = ref<FragranceSelectionConfig>(
    SELECTION_CONFIG_DEFAULT,
  )

  // Global Promise handlers

  type ResolveFunction = (variants: VariantSelection[]) => void
  const resolveSelection: Ref<ResolveFunction> = ref(() => [])

  type RejectFunction = (reason: string) => void
  const rejectSelection: Ref<RejectFunction> = ref(() => "")

  const variantSelectionToProductSelection = (
    variantSelection: VariantSelection[],
  ): ProductSelection => {
    return variantSelection.reduce((acc, variant) => {
      if (!variant.productId) {
        console.error('could not find "productId" in variant')
        return acc
      }
      return {
        ...acc,
        [variant.productId]: variant.quantity,
      }
    }, {})
  }

  // Initialisation of the dialog
  const getFragrances = async (
    config: FragranceSelectionConfig,
  ): Promise<VariantSelection[]> => {
    preSelected.value = config.selectedProducts
      ? variantSelectionToProductSelection(config.selectedProducts)
      : {}

    if (Object.keys(preSelected.value).length > 0) {
      completeOverlayVisible.value = true
    }

    selectionConfig.value = config
    const resolvePromise = new Promise<VariantSelection[]>(
      (resolve, reject) => {
        resolveSelection.value = resolve
        rejectSelection.value = reject
      },
    )

    const allFragrances = (await getAllFragrances()) || []
    fullFragranceList.value = allFragrances
    products.value = allFragrances

    csDialogRef.value?.openModal()
    userFacets.value = []

    return resolvePromise
  }

  defineExpose({
    getFragrances,
  })

  // Local UI State
  const loading = ref(false)
  const searchOpen = ref(false)
  const completeOverlayVisible = ref(false)
  const selectedFragrance = ref("")
  const searchQuery = ref("")
  const tmpBeforeCustomChoice = ref("")
  const userFacets = ref<string[]>([])
  const preSelected: Ref<ProductSelection> = ref({})
  const fullFragranceList: Ref<AlgoliaFragrance[]> = ref([])
  const products: Ref<AlgoliaFragrance[]> = ref([])

  // Dom Refs
  const csDialogRef = ref<InstanceType<typeof CsDialog> | null>(null)
  const completeOverlay: Ref<HTMLDivElement | null> = ref(null)
  const searchInput: Ref<HTMLInputElement | null> = ref(null)
  const completeResetButton: Ref<HTMLButtonElement | null> = ref(null)
  const completeConfirmButton: Ref<HTMLButtonElement | null> = ref(null)

  //  Amount of selected variants before confirming in order to display Choose x more ...
  const preselectQuantity = computed(() => {
    if (preSelected.value) {
      return Object.values(preSelected.value).reduce(
        (acc, value) => acc + value,
        0,
      )
    }
    return selectionConfig.value.quantity
  })

  const submit = () => {
    const FragranceSelection: VariantSelection[] = Object.keys(
      preSelected.value,
    ).map((productId) => {
      const product = fullFragranceList.value?.find(
        (product) => product.product_id === parseInt(productId),
      )
      const variant = product?.variants.find(
        (variant) => variant.type_code === selectionConfig.value.type_code,
      )

      if (!variant || !product) {
        return {
          productId: "",
          variantId: "",
          quantity: 0,
        }
      }

      return {
        productId,
        variantId: variant?.id.toString(),
        name: product.name,
        quantity: preSelected.value[productId],
      }
    })

    resolveSelection.value(FragranceSelection)
    csDialogRef.value?.close()
    uiStore.blockScroll = false
    searchQuery.value = ""
    preSelected.value = {}
  }

  const dialogClose = () => {
    if (preselectQuantity.value !== selectionConfig.value.quantity) {
      cancelChooseProcess()
    }
  }

  const focusCompleteButton = () => {
    completeConfirmButton.value?.focus()
  }

  const cancelChooseProcess = () => {
    rejectSelection.value("error1")
    csDialogRef.value?.close()
    searchOpen.value = false
    uiStore.blockScroll = false
    preSelected.value = {}
    selectedFragrance.value = tmpBeforeCustomChoice.value
  }

  const createRichData = (productdIds: ProductSelection) => {
    if (productdIds) {
      return Object.keys(productdIds).map((id) => {
        return {
          product: fullFragranceList.value?.find(
            (product) => product.product_id === parseInt(id),
          ),
          amount: productdIds[id],
        }
      })
    }
  }

  // Category Filtering
  const { data: categories } = useLazyAsyncData(async () => {
    try {
      const specific = await index.search<string>("", {
        facets: ["searchable_categories"],
        hitsPerPage: 0, // We only care about the facet counts, not the search results
      })

      return specific.facets?.searchable_categories
    } catch (error) {
      handleError(error)
    }
  })

  const applyCategory = (category: string) => {
    loading.value = true
    if (userFacets.value.includes(category)) {
      userFacets.value = []
    } else {
      userFacets.value = [category]
    }
    search()
  }

  const getAllFragrances = async () => {
    loading.value = true
    try {
      const all = await index.search<AlgoliaFragrance>("", {
        hitsPerPage: 99999,
        facetFilters: selectionConfig.value.predefinedFacets,
        sortFacetValuesBy: "alpha",
      })
      return all.hits
    } catch (error) {
      handleError(error)
    } finally {
      loading.value = false
    }
  }

  const toggleSearch = () => {
    if (searchOpen.value) {
      searchQuery.value = ""
    } else {
      userFacets.value = []
      searchInput.value?.focus()
    }
    searchOpen.value = !searchOpen.value
  }

  // Selection Functions

  const preSelect = (id: number) => {
    // In case of single selection we immediately submit
    if (selectionConfig.value.quantity === 1) {
      preSelected.value = { [id]: 1 }
      submit()
      return
    }

    if (preSelected.value[id] === undefined) {
      preSelected.value[id] = 1
    } else {
      preSelected.value[id] = preSelected.value[id] + 1
    }
  }

  const minusPreselect = (id: number) => {
    if (preSelected.value[id] === 1) {
      delete preSelected.value[id]
    } else {
      preSelected.value = {
        ...preSelected.value,
        [id]: (preSelected.value[id] || 0) - 1,
      }
    }
  }

  // the facets are configured as searcharbale_categories in the algolia index
  const addSearchableString = (categories: string[]) =>
    categories.map((category) => `searchable_categories:${category}`)

  const search = debounce(async () => {
    try {
      loading.value = true
      const all = await index.search<AlgoliaFragrance>(searchQuery.value, {
        hitsPerPage: 99999,
        facetFilters: [
          selectionConfig.value?.predefinedFacets || [],
          ...addSearchableString(userFacets.value),
        ],
        sortFacetValuesBy: "alpha",
      })
      products.value = all.hits
    } catch (error) {
      handleError(error)
    } finally {
      loading.value = false
    }
  }, 300)

  const debouncedSearch = () => {
    loading.value = true
    search()
  }

  // Perform Search on query Change
  watch(
    () => searchQuery.value,
    async () => {
      await debouncedSearch()
    },
  )

  // Engage Success Overlay Focus Trap
  const focusResetButton = () => {
    completeResetButton.value?.focus()
  }

  // Close Success Overlay
  const cancelSuccessOverlay = () => {
    completeOverlayVisible.value = false
  }

  // Toggle Success Overlay
  watch(
    () => preselectQuantity.value,
    async (value) => {
      if (
        selectionConfig.value.quantity > 1 &&
        value === selectionConfig.value.quantity
      ) {
        await nextTick()
        completeResetButton.value?.focus()
        completeOverlayVisible.value = true
      }
    },
  )
</script>
